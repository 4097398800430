import * as React from "react"

import ToolScreen from '../../../../components/toolScreen'


const Tool = ({ data }) => (
  <ToolScreen
    name="Adobe Illustrator"
    img_name="adobe_illustrator_logo.png"
    link="https://www.adobe.com/illustrator/"
    description="Adobe Illustrator is the best screen design tool for professional designers looking for a complete solution for designing vector graphics."
    twitterHandleTool="Illustrator(@AdobeDesign)"
  />
)

export default Tool;


